@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
:root {
  --blue: rgb(46, 48, 139);
  --yellow: #fdee2e;
  --red-def: #c5223b;
  --red-light: rgba(197, 34, 59, 0.2);
  --green-def: #19a59a;
  --green-light: rgba(25, 165, 154, 0.2);
  --grey-light: rgba(0, 0, 0, 0.04);
  --grey-def: rgba(0, 0, 0, .35);
  --grey-dark: rgba(0, 0, 0, 0.851);
  --white: #fff;
  --white-light: rgba(255, 255, 255, 0.5);
  --black: #000;
  --shadow-light: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  --shadow-def: 0 0 5px 0 rgba(134, 51, 51, 0.3);
  --shadow-dark: 0 0 15px rgba(0, 0, 0, 0.5);
  --drop-shadow: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
  --drop-shadow-xl: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
  --border-radius: .2em;
}

::selection {
  background: var(--grey-def);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--grey-light);
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey-dark);
}

main > section {
  padding-inline: calc(1rem + 5vw);
  z-index: -1;
}
@media (min-width: 1440px) {
  main > section {
    padding-inline: calc(1rem + 15vw);
  }
}
@media (max-width: 450px) {
  main > section {
    padding-inline: 1rem;
  }
}

mark {
  background-color: var(--black);
  color: var(--white);
  padding: 5px;
  line-height: 2.43rem;
}

h1 {
  margin: 1rem 0;
  font-family: "Perpetua", serif;
  font-size: 4.25rem;
  font-weight: normal;
  line-height: 1;
}

h2 {
  margin: 1rem 0;
  font-family: "Perpetua", serif;
  font-size: 2.25rem;
  font-weight: normal;
  line-height: 1;
}

h3 {
  margin: 1rem 0;
  font-family: "Perpetua", serif;
  font-size: 1.5833333333rem;
  font-weight: normal;
  line-height: 1;
}

h4 {
  margin: 1rem 0;
  font-family: "Perpetua", serif;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1;
}

p {
  font-size: 1rem;
  font-family: "Heebo", sans-serif;
}

img {
  border-radius: var(--border-radius);
}

a {
  color: var(--black);
  text-decoration: none;
  font-weight: bold;
}

input {
  accent-color: var(--black);
}

main.content h1.main-title {
  margin: 1.5em auto 0.5em;
  font-size: 4rem;
}

.btn {
  font-family: "Heebo", sans-serif;
  font-weight: bold;
  display: inline-flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
  color: var(--white);
  background-color: var(--black);
  min-width: 10rem;
  max-width: 20rem;
  max-height: 3rem;
  transition: 0.4s;
  justify-content: center;
  box-shadow: var(--shadow-light);
  outline-color: var(--black);
  outline-width: 1px;
  outline-style: solid;
  outline-offset: 0.2em;
  transition: outline-offset, outline;
  transition-duration: 0.5s;
}
.btn:hover, .btn:active {
  box-shadow: var(--shadow-def);
  outline-offset: -0.2em;
  outline-color: var(--white);
}
.btn:focus {
  outline-style: dotted;
  outline-offset: 0.1rem;
  outline-color: var(--yellow-def);
  outline-width: 0.15rem;
}
.btn.btn-danger {
  background-color: var(--red-def);
  border-color: var(--red-def);
  outline-color: var(--red-def);
}
.btn.btn-danger:hover {
  outline-color: var(--white);
}
.btn.btn-light {
  background-color: var(--white);
  color: var(--black);
  outline-color: var(--white);
}
.btn.btn-light:hover, .btn.btn-light:active {
  outline-color: var(--black);
}
.btn.disabled {
  filter: brightness(0.5);
  cursor: not-allowed;
}

.absolute-center {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.btn-sm {
  padding: 0.2rem 0.5rem;
  font-weight: normal;
  font-size: 0.9rem;
  min-width: auto;
}

.bg-image-1 {
  background-image: url("/images/assets/layout/hero-banner.jpg");
  background-color: var(--black);
  background-position: top;
  background-size: contain;
  background-attachment: fixed;
  position: relative;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey-dark);
  transition: background-color 0.5s ease-in-out;
  backdrop-filter: blur(1px);
  z-index: -1;
}

.outline {
  outline: solid 1px var(--white);
  outline-offset: -0.3em;
  border-radius: 0 !important;
  border: none !important;
}

.link-hover {
  background: linear-gradient(to top, var(--black) 0%, var(--black) 10%, transparent 10.1%) no-repeat;
  background-size: 0 100%;
  transition: background-size 0.3s;
}
.link-hover:hover, .link-hover:active {
  background-size: 100% 100%;
}

.cta-container {
  margin: 5rem 0;
  padding: 3rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cta-container .cta-content {
  text-align: center;
  color: var(--white);
  min-width: 25rem;
  max-width: 40rem;
  width: 66%;
}
@media (max-width: 768px) {
  .cta-container .cta-content {
    min-width: 100%;
    width: 100%;
  }
}
.cta-container .cta-btn {
  text-align: center;
  width: 33%;
}
@media (max-width: 768px) {
  .cta-container .cta-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0 !important;
}
.tag-container .tag-item {
  padding: 0.2rem 0.4rem;
  margin: 0.2rem 0.2rem;
  background-color: var(--grey-light);
  list-style: none;
}

.card-container.visible .cards .card {
  opacity: 1;
}
.card-container .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  justify-content: center;
  align-items: stretch;
}
@media (max-width: 1024px) {
  .card-container .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 450px) {
  .card-container .cards {
    grid-template-columns: 1fr;
  }
}
.card-container .cards .card-1 {
  transition: opacity 0.5s linear 0.2s;
}
.card-container .cards .card-2 {
  transition: opacity 0.5s linear 0.4s;
}
.card-container .cards .card-3 {
  transition: opacity 0.5s linear 0.6s;
}
.card-container .cards .card-4 {
  transition: opacity 0.5s linear 0.8s;
}
.card-container .cards .card-5 {
  transition: opacity 0.5s linear 1s;
}
.card-container .cards .card-6 {
  transition: opacity 0.5s linear 1.2s;
}
.card-container .cards .card-7 {
  transition: opacity 0.5s linear 1.4s;
}
.card-container .cards .card-8 {
  transition: opacity 0.5s linear 1.6s;
}
.card-container .cards .card-9 {
  transition: opacity 0.5s linear 1.8s;
}
.card-container .cards .card-10 {
  transition: opacity 0.5s linear 2s;
}
.card-container .cards .card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  justify-content: flex-start;
  text-align: center;
  border: solid 2px var(--black);
  border-radius: var(--border-radius);
  opacity: 0;
}
.card-container .cards .card.nactive {
  display: none;
}
.card-container .cards .card.card-article {
  max-width: 28rem;
  margin: 0.2rem 0.5rem;
}
.card-container .cards .card .card-header {
  position: relative;
  min-width: 100%;
  height: 150px;
  overflow: hidden;
}
.card-container .cards .card .card-header img {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}
.card-container .cards .card .card-body {
  padding: 1em 0.5em;
}
.card-container .cards .card .card-body svg {
  display: block;
  margin: 0 auto;
  width: 3rem;
  height: auto;
  max-height: 4rem;
}
.card-container .cards .card .card-body .card-desc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card-container .cards .card .card-footer {
  color: var(--grey-def);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}
.card-container .cards-2 {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .card-container .cards-2 {
    grid-template-columns: 1fr;
  }
}

.modalHandler {
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
.modalHandler:hover {
  background-color: var(--grey-light);
}

.sep {
  border-bottom: solid 1px var(--grey-def);
  margin-bottom: 1rem;
}

.tiny-content ul {
  padding-left: 2rem;
  margin-block: 2rem;
}
.tiny-content ul li {
  list-style: disc;
}

#Like {
  color: var(--grey-def);
}
#Like:hover {
  color: var(--grey-dark);
}
#Like svg,
#Like img {
  cursor: pointer;
  margin-left: 1rem;
}
#Like .active svg,
#Like .active img {
  fill: var(--grey-light);
}

.modal-container {
  margin: 1rem 1rem;
  position: fixed;
  bottom: 2em;
  right: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 3em 1fr;
  align-items: center;
  gap: 0.2rem;
  box-shadow: var(--shadow-def);
  border-top: solid 2px var(--black);
  background-color: var(--white);
  max-width: 20rem;
  min-width: 10rem;
  padding: 1rem;
  animation: slideRight 1s linear;
}
.modal-container .modal-close {
  position: absolute;
  right: 0.5em;
  top: 0.2em;
  cursor: pointer;
}
.modal-container .modal-close svg {
  width: 1.3rem;
  height: 1.3rem;
}
.modal-container .modal-icon {
  margin: 0 auto;
}
.modal-container .modal-content .modal-title {
  font-weight: bold;
}

#CookieNotice {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  margin-block: 0.5rem;
}
#CookieNotice .cookie-notice {
  background: var(--white);
  padding: 0.2rem 1rem;
  color: var(--black);
  border-top: solid 2px var(--black);
  max-width: 35rem;
  min-width: 18rem;
  position: relative;
  text-align: center;
  box-shadow: var(--shadow-def);
}
#CookieNotice .cookie-notice .cookie-btn {
  margin-top: 0.2rem;
}

.form-container input:not([type=checkbox], [type=submit], [type=radio]),
.form-container textarea,
.form-container select {
  box-shadow: 3px 3px 0px 0px var(--grey-def);
  transition: 0.5s;
  outline: none;
}
.form-container input:not([type=checkbox], [type=submit], [type=radio]):focus, .form-container input:not([type=checkbox], [type=submit], [type=radio]):active,
.form-container textarea:focus,
.form-container textarea:active,
.form-container select:focus,
.form-container select:active {
  box-shadow: 5px 5px 0px 0px var(--grey-dark);
}
.form-container input:not([type=radio], [type=checkbox]),
.form-container select,
.form-container textarea {
  max-width: 30rem;
  min-width: 7rem;
}
.form-container .form-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.form-container .form-title {
  text-align: center;
}
.form-container .form-input {
  position: relative;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.2rem;
  border-style: solid;
  border-width: 1px;
  border-color: var(--grey-dark);
  width: 100%;
}
.form-container .form-errors {
  background-color: var(--red-light);
  border: 1px solid var(--red-def);
  padding: 1rem;
}
.form-container .form-errors .form-error {
  color: var(--red-def);
}
.form-container .honeypot {
  opacity: 0;
  visibility: hidden;
  cursor: none;
  position: absolute;
}
.form-container .tox .tox-editor-header {
  z-index: 0;
}
.form-container .multipleSelect input {
  box-shadow: none;
}
.form-container .multipleSelect input:focus, .form-container .multipleSelect input:active {
  box-shadow: none;
}
.form-container input[type=checkbox].form-check {
  display: none;
}
.form-container input[type=checkbox].form-check, .form-container input[type=checkbox].form-check:after, .form-container input[type=checkbox].form-check:before, .form-container input[type=checkbox].form-check *, .form-container input[type=checkbox].form-check *:after, .form-container input[type=checkbox].form-check *:before, .form-container input[type=checkbox].form-check + .form-check-label {
  box-sizing: border-box;
}
.form-container input[type=checkbox].form-check::selection, .form-container input[type=checkbox].form-check:after::selection, .form-container input[type=checkbox].form-check:before::selection, .form-container input[type=checkbox].form-check *::selection, .form-container input[type=checkbox].form-check *:after::selection, .form-container input[type=checkbox].form-check *:before::selection, .form-container input[type=checkbox].form-check + .form-check-label::selection {
  background: none;
}
.form-container input[type=checkbox].form-check + .form-check-label {
  outline: 0;
  width: 2em;
  height: 1.1em;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 2px;
  transition: all 0.2s ease;
  background: var(--white);
  border: 2px solid var(--grey-def);
}
.form-container input[type=checkbox].form-check + .form-check-label:after, .form-container input[type=checkbox].form-check + .form-check-label:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.form-container input[type=checkbox].form-check + .form-check-label:after {
  left: 0;
  transition: all 0.2s ease;
  background: var(--grey-def);
  content: "";
}
.form-container input[type=checkbox].form-check + .form-check-label:before {
  display: none;
}
.form-container input[type=checkbox].form-check:checked + .form-check-label {
  border: 2px solid var(--green-def);
}
.form-container input[type=checkbox].form-check:checked + .form-check-label:after {
  left: 50%;
  left: 50%;
  background: var(--green-def);
}

@keyframes shrink {
  0% {
    -webkit-clip-path: circle(50%);
    clip-path: circle(50%);
  }
  100% {
    -webkit-clip-path: circle(0%);
    clip-path: circle(0%);
  }
}
@keyframes gridHide {
  0% {
    opacity: 1;
    pointer-events: inherit;
    position: static;
    visibility: inherit;
  }
  99% {
    opacity: 0;
  }
  100% {
    pointer-events: none;
    position: absolute;
    visibility: hidden;
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}